/**
 * Created by louidorpc on 12/7/20.
 */
import React, {useEffect, useState} from "react";
import {useParams, withRouter} from "react-router";
import StairHeader from "../../layout/common/SatairHeader";
import {sectionPartners} from "../home/sections"
import PostList from "./postList"
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Posts} from '../../../constants/options/data'


const RecrutementPageManager = () => {
    const {page} = useParams();

    const {loading, posts} = useSelector(state => ({
        posts: Posts
    }));

    return (
        <>
        <StairHeader title={`recrutement`} subtitle={`recrutement`} mt={`20px`}/>
        <section class="single-department-wrap-layout1 bg-light-accent100 pt-4  pb-4">
            <div class="container">
                <div className="row" style={{background: '#fff'}}>
                    <div className="col-xl-9 col-lg-8 col-12">
                        <div class="widget widget-search pb-5">
                            <h3 class="section-title title-bar-primary">Filtrage</h3>
                            <div class="input-group stylish-input-group">
                                <input type="text" class="form-control"
                                       placeholder="Rechercher par categorie,domaine ou Spécialité. . ."/>
                                <span class="input-group-addon">
                                    <button type="submit">
                                        <span class="flaticon-search" aria-hidden="true"></span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-xl-4 col-lg-4 col-12">
                        <div className="widget widget-department-info ">
                            <h3 className="section-title title-bar-primary">
                                <FormattedMessage id="app.label.category"
                                                  defaultMessage=""/></h3>

                            <ul className="nav nav-tabs tab-nav-list bg-white">
                                <li className="nav-item">
                                    <a className={'active'} href={`#founder`} data-toggle="tab"
                                       aria-expanded="false">
                                        <span>MEDECINS DE FAMILLE</span>
                                        <small className="d-block text-uppercase">
                                        </small>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className={''} href={`#leadership`} data-toggle="tab"
                                       aria-expanded="false">
                                        <span>MEDECIN URGENTISTE</span>
                                        <small className="d-block text-uppercase">
                                            {/*{site.description}*/}
                                        </small>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>


                    <div className="col-xl-8 col-lg-8 col-12">
                        <div class="widget widget-search pb-5">
                            <h3 class="section-title title-bar-primary">Postes</h3>

                            {posts && posts.length > 0 ? posts.map(value => (
                                    <div className="pb-5">
                                        <PostList m="12" d="22" y="2020" postItem={value}/>
                                    </div>
                                )) : null}
                        </div>
                    </div>


                </div>
            </div>
        </section>
        </>

    )
}

export default React.memo(withRouter(RecrutementPageManager))

