/**
 * Created by louidorpc on 12/7/20.
 */
import React, {useEffect, useState} from "react";
import {useParams, withRouter} from "react-router";
import {Accordion, Card} from 'react-bootstrap'

const PostList = ({m, d, y, postItem}) => {

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        let difference = +new Date(`${m}/${d}/${y}`) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                j: Math.floor(difference / (1000 * 60 * 60 * 24)),
                h: Math.floor((difference / (1000 * 60 * 60)) % 24),
                mn: Math.floor((difference / 1000 / 60) % 60),
                s: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;

    }


    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });


    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <span style={{fontSize: '18px'}}>
      {timeLeft[interval]} {interval}{" "}
    </span>
        );
    });

    return (<div class="blog-author pb-5">
        <div class="media media-none--xs card">
            <div class="media-body pb-5">
                <button class="item-btn float-right mr-4 btn-primary mt-4"
                        style={{cursor: 'pointer'}}>Postuler
                </button>
                <h4 class="float-left m-4 font-weight-bolder"><b>{postItem && postItem.title}</b> </h4>

                <div className="clearfix"></div>
                <p className="ml-4">{postItem && postItem.subtitle}</p>


                <Accordion defaultActiveKey="0" className="mr-5 ml-5">

                    <Accordion.Toggle as={Card.Header} eventKey="1" style={{border: 'none'}}>
                        <button style={{cursor:'pointer'}} className="action-items-primary-btn">Lire plus</button>
                        <div class="badge-primary float-right">
                            <div className="m-2">{timerComponents.length ? timerComponents :
                                <span>Le temps est écoulé!</span>}</div>
                        </div>

                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <div className="single-departments-box-layout1 pb-4">
                                <div className="item-content">

                                    {postItem && postItem.desc.length > 0 ? postItem.desc.map(value => (
                                            <p className="">{value}</p>)) : null}

                                    {postItem && postItem.documents.length > 0 ?
                                        <ul className="department-info deps">
                                            <h4 className="pb-0 pt-4">Présentation du dossier du Candidat:</h4>
                                            {postItem.documents.map(value => (
                                                <li>{value}</li>))}
                                        </ul> : <h1>hello</h1>}


                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>

                </Accordion>

            </div>


        </div>

    </div>)
}

export default PostList;