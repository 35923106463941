export const header = {

    "menuItems": [
        {
            "menu": "network",
            "url": "/network",
            "items": []
        },
        {
            "menu": "about",
            "url": "/about",
            "items": [
                // {"label": "leadership", "url": "/about/leadership"},
                // {"label": "origin", "url": "/about/origin"},
                // {"label": "impact", "url": "/about/impact"},
                // {"label": "focus", "url": "/about/focus"},
                // {"label": "vision_mission", "url": "/about/vision-mission"},
            ]
        },
        {
            "menu": "sectors",
            "url": "/sectors",
            "items": []
        },
        {
            "menu": "articles",
            "url": "/news",
            "items": []
        },
        {
            "menu": "help",
            "url": "#",
            "items": [
                {"label": "help_faq", "url": "/help/faqs"},
                {"label": "contact", "url": "/help/contact"},
            ]
        },
        {
            "menu": "emplois",
            "url": "/jobs",
            "items": []
        },
        {
            "menu": "recherche",
            "url": "/research",
            "items": []
        },
        {
            "menu": "covid19",
            "url": "/covid19",
            "items": []
        },
        // {
        //     "menu": "recrutement",
        //     "url": "/recrutement",
        //     "items": []
        // },
    ]
}


