import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link, useParams, withRouter} from "react-router-dom";
import Thumbnail from "./Thumbnail";
import {useDispatch, useSelector} from "react-redux";
import {News} from '../../../../constants/options/data'
import {useContext, useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";
import StairHeader from "../../../layout/common/SatairHeader";
import moment from "moment";
import {intlConverter} from "../../../../constants/utils";
import {GlobalContext} from "../../../index";


const BlogList = () => {
    const {module} = useParams();
    const dispatch = useDispatch();
    const criteria = {options: ['news']}


    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {loading, items} = useSelector(state => ({
        loading: state.pagesReducer.blog.loadingItems,
        items: state.pagesReducer.blog.item,
    }));
    console.log("=+++++++++=hdhdhdhdhdh===+++++++===", items)
    const {language} = useContext(GlobalContext)

    return (
        <>
            <StairHeader title={`blog`} subtitle={`blog`} mt="50px"/>
            <section class="blog-wrap-layout2 bg-light-primary100 pt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-9 col-lg-8 col-12">
                            <div class="row">
                                {items && items.length > 0 ? items.map(value => value.status &&
                                    <div class="single-item col-md-6 col-sm-12 col-12">
                                        <div class="blog-box-layout4">
                                            <div class="item-img">
                                                <a href={`/news/detail/${value.id}`}>
                                                    <img src={value.fileName} class="img-fluid" alt="blog"/>
                                                </a>
                                                <div class="post-date">{value.day}
                                                    <span>{moment(value.created).format('MM YYYY')}</span>
                                                </div>
                                            </div>
                                            <div class="item-content">
                                                <h3 className="item-title">
                                                    {/*<a href="/blog/detail/1">*/}
                                                    <a href={`/news/detail/${value.id}`}>
                                                        {intlConverter(value.title, language)}</a>
                                                </h3>
                                                <p dangerouslySetInnerHTML={{
                                                    __html: intlConverter(value.content, language)
                                                }} className={'item-title'}></p>

                                            </div>
                                        </div>
                                    </div>) : null}
                            </div>

                        </div>
                        <div class="sidebar-widget-area sidebar-break-md col-xl-3 col-lg-4 col-12">

                            <div className="card">
                                <div class="widget widget-recent m-4">
                                    <h3 class="section-title title-bar-primary">Articles Similaires</h3>
                                    {items && items.length <= 3 ? items.map(value => value.status &&
                                        <div class="media">
                                            <a href={`/news/detail/${value.id}`}>
                                                <img src={value.fileName} alt="news" class="img-fluid"
                                                     width="100"
                                                     height="100"/>
                                            </a>
                                            <div class="media-body space-sm">
                                                <div class="post-date">{moment(value.created).format('MM YYYY')}</div>
                                                <h4 class="post-title">
                                                    <a href={`/news/detail/${value.id}`}> {intlConverter(value.title, language)}</a>
                                                </h4>
                                            </div>
                                        </div>) : null}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default React.memo(withRouter(BlogList))

