import * as React from "react";
import {NavLink, withRouter, useParams} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import Map from '../../help/map'
import {useContext} from "react";
import {GlobalContext} from "../../../index";
import {intlConverter} from "../../../../constants/utils";
import Item from "./Item";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';


const SideBar = ({sites, lang}) => {
    const {module, page, id} = useParams();
    const {language} = useContext(GlobalContext)

    return (<>
            <div class="row" id="no-equal-gallery">

                <div className="sidebar-widget-area sidebar-break-md col-xl-3 col-lg-4 col-12 no-equal-item">
                    <div className="widget widget-department-info blockShow">
                        <div className="headerss">
                            <h3 className="section-title title-bar-primary"><FormattedMessage id={`app.label.all_sites`}
                                                                                              defaultMessage="Tous les sites"/>
                            </h3>
                            <ul className="nav nav-tabs tab-nav-list bg-white">
                                <li className="nav-item">

                                    <a href={`/network`}
                                       className={page === undefined ? 'active' : ''}>
                                                    <span
                                                        style={{fontSize: '14px'}}><FormattedMessage
                                                        id={'app.label.all'}/>
                                                    </span>
                                    </a>
                                </li>
                                {sites && sites.map((site, i) => {
                                    if (site.id === page) {
                                        return (
                                            <li className="nav-item">

                                                <a href={`/network/${site.id}`}
                                                   className={'active'}>
                                                    <span
                                                        style={{fontSize: '14px'}}>{intlConverter(site.name, language)}</span>
                                                </a>
                                            </li>)
                                    } else if (site.id !== page || page === undefined) {
                                        return (
                                            <li className="nav-item">

                                                <a href={`/network/${site.id}`}
                                                   className={''}>
                                                    <span
                                                        style={{fontSize: '14px'}}>{intlConverter(site.name, language)}</span>

                                                </a>
                                            </li>
                                        )
                                    }
                                })}

                            </ul>
                        </div>
                    </div>
                    {sites && sites.map((site, i) => {
                        if (site.id === page) {
                            return (
                                <div class="widget widget-call-to-action-light">
                                    <div class="media">
                                        <img src="/assets/img/figure/figure6.png" alt="figure"/>
                                        <div class="media-body space-sm">
                                            <h4>En Cas d'urgence</h4>
                                            <span><a href="tel:+50900000000">{site.phoneArray.join(' / ')}</a></span>
                                            <span><a href="tel:+50900000000">{site.addressArray.join(' / ')}</a></span>
                                            <span><a href="tel:+50900000000">{site.emailArray.join(' / ')}</a></span>

                                        </div>
                                    </div>
                                </div>)
                        }
                    })}

                </div>

                <div className="col-xl-9 col-lg-8 col-12 no-equal-item">

                    <div className="tab-content contentt">
                        <h3 className="item-title title-bar-primary5">Nos différents Sites</h3>
                                <Timeline style={{width: '100%'}} align="left">
                                    {sites && sites.map((value, i) => {
                                        if (value.id === page) {
                                            return (

                                                    <TimelineItem style={{width: '100%'}} align="left">
                                                        <TimelineSeparator>
                                                            <TimelineDot color={'primary'}/>
                                                            <TimelineConnector/>
                                                        </TimelineSeparator>
                                                        <TimelineContent style={{width: '100%'}}> <Item item={value}
                                                                                                        language={language}
                                                                                                        page={page}/></TimelineContent>
                                                    </TimelineItem>

                                            )
                                        } else if (page === undefined) {
                                            return (
                                                    <TimelineItem>
                                                        <TimelineSeparator>
                                                            <TimelineDot color={'#f69000'} style={{width:'100%',color:'#f690000'}}/>
                                                            <TimelineConnector  color={'#f69000'}/>
                                                        </TimelineSeparator>
                                                        <TimelineContent><Item item={value} language={language}
                                                                               page={page}
                                                                               index={i + 1}/></TimelineContent>
                                                    </TimelineItem>)
                                        }
                                    })}
                                </Timeline>
                            </div>
                        </div>

                        {/*{sites && sites.map((value, i) => {*/}
                        {/*    if (value.id === page) {*/}
                        {/*        return (*/}
                        {/*            <Item item={value} language={language} page={page}/>*/}
                        {/*        )*/}
                        {/*    } else if (page === undefined) {*/}
                        {/*        return (<Item item={value} language={language} page={page} index={i + 1}/>)*/}
                        {/*    }*/}
                        {/*})}*/}

            </div>


        </>

    )
}


export default React.memo(withRouter(SideBar))

