import * as React from "react";
import {useParams, withRouter} from "react-router";
import StairHeader from "../../layout/common/SatairHeader";
import SideBar from './SideBar';
import {sectionPartners} from "../home/sections"
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import actions from "../../../thunks/common/coreThunks"
import {injectIntl} from "react-intl";


const AboutPageManager = injectIntl(({intl}) => {
    const {page} = useParams();

    const dispatch = useDispatch();

    const {module, secondLevelPage, action} = useParams();
    const params = {intl, options: ['pages', 'about']}


    useEffect(() => {
        dispatch(actions.loadItems(params))
    }, []);

    const {item, loading,} = useSelector(state => ({
        item: state.pagesReducer.about.item,
        loading: state.pagesReducer.about.loadingItem
    }));


    return (
        <React.Fragment>
            <StairHeader title={`about`} subtitle={`about`} mt={`20px`}/>
            <section class="single-department-wrap-layout1 bg-light-primary100 pt-1  pb-4">
                <div class="container">
                    <SideBar item={item}/>
                </div>
            </section>
        </React.Fragment>

    )
})

export default React.memo(withRouter(AboutPageManager))

