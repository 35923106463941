import * as React from "react";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {homeAbout, homeSectors, Sectors} from "../../../constants/options/data";
import {injectIntl} from "react-intl";
import Slider from "./slider";
import {Section1, SectionAbout, sectionSector, sectionTeste, sectionNews, sectionPartners} from "./sections";
import {useParams, withRouter} from "react-router";
import actions from "../../../thunks/common/coreThunks";
import {useContext, useEffect} from "react";
import {connect, useDispatch, useSelector,} from "react-redux";
import ScriptTag from "react-script-tag";
import {bindActionCreators} from "redux";
import {withCookies} from "react-cookie";
import {GlobalContext} from "../../index";


const GlobalHomePage = injectIntl(({intl}) => {

        useDocumentTitle(getIntlMsg(intl, `app.label.home`));
        const dispatch = useDispatch();

        const {module, secondLevelPage, action} = useParams();
        const params = {intl, options: ['pages', 'home']}


        useEffect(() => {
            dispatch(actions.loadItems(params))
        }, []);

        const {item, loading, slides} = useSelector(state => ({
            item: state.pagesReducer.home.item,
            slides: state?.pagesReducer?.home?.item?.slider?.slides || [],
            loading: state.pagesReducer.home.loadingItem
        }));
        const {settings, language} = useContext(GlobalContext);


        return (
            <React.Fragment>
                {loading ? <><Slider item={item} slides={slides} language={language}/> </> : null}
                <Section1 item={item} language={language}/>
                {SectionAbout(homeAbout, settings, language)}
                {item && item.sectors.length > 0 ? sectionSector(Sectors, item, language) : null}
                {item && item.testimonials.length > 0 ? sectionTeste(homeSectors, item, language) : null}
                {item && item.news.length > 0 ? sectionNews(homeSectors, item, language) : null}}

            </React.Fragment>
        );
    }
);
export default React.memo(withRouter(GlobalHomePage))

