import React, {useContext} from "react";
import {Route} from "react-router";
import {Redirect} from "react-router-dom";
import {hasEntryAccess} from "../../../constants/utils/security";
import {GlobalContext} from "../../index";
import ScriptTag from 'react-script-tag';


const PrivateRoute = ({component: Component, ...rest}) => {

    const {location: {pathname}} = {...rest};

    const customPath = pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length);
    const entry = pathname === '/' ? 'home' : customPath.replace(/\//g, '');
    return (
        <>
            {pathname === '/' ? <Redirect to={'/home'}/> :
                <Route {...rest} render={(props) => (<Component {...props}/>)}/>}
        </>
    )
};

export default PrivateRoute;