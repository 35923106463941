import types from "../actions/types/pageTypes";
import initialState from "../../constants/utils/initialState";

const sectorReducer = (state = initialState.pagesReducer, action) => {

        switch (action.type) {


            /**
             * LOAD LOAD_HOME_PAGE
             * */
            case types.LOAD_HOME_PAGE:
                return {...state, home: {loadingItem: false}};
            case types.LOAD_PAGES_HOME_PAGE_SUCCEEDED:
                return {...state, home: {item: action.payload, loadingItem: true}};
            case types.LOAD_HOME_PAGE_FAILED:
                return {...state, home: {loadingItem: false}};



            /**
             * LOAD LOAD_ABOUT_PAGE
             * */
            case types.LOAD_ABOUT_PAGE:
                return {...state, about: {loadingItem: action.payload}};
            case types.LOAD_PAGES_ABOUT_PAGE_SUCCEEDED:
                return {...state, about: {item: action.payload, loadingItem: false}};
            case types.LOAD_ABOUT_PAGE_FAILED:
                return {...state, about: {loadingItem: false}};




            /**
             * LOAD LOAD_SECTORS_PAGE
             * */
            case types.LOAD_SECTORS_PAGE:
                return {...state, sector: {loadingItems: action.payload}};
            case types.LOAD_PAGES_SECTOR_PAGE_SUCCEEDED:
                return {...state, sector: {items: action.payload, loadingItems: false}};
            case types.LOAD_SECTORS_PAGE_FAILED:
                return {...state, sector: {loadingItems: false}};

            /**
             * LOAD LOAD_FAQS_PAGE
             * */
            case types.LOAD_FAQS_PAGE:
                return {...state, faqs: {loadingItems: action.payload}};
            case types.LOAD_FAQS_PAGE_SUCCEEDED:
                return {...state, faqs: {items: action.payload, loadingItems: false}};
            case types.LOAD_FAQS_PAGE_FAILED:
                return {...state, faqs: {loadingItems: false}};


            /**
             * LOAD LOAD_FAQS_PAGE
             * */
            case types.LOAD_COVID_PAGE:
                return {...state, covid: {loadingItems: action.payload}};
            case types.LOAD_COVID_PAGE_SUCCEEDED:
                return {...state, covid: {items: action.payload, loadingItems: false}};
            case types.LOAD_COVID_PAGE_FAILED:
                return {...state, covid: {loadingItems: false}};


            /**
             * LOAD LOAD_SECTOR_PAGE
             * */
            case types.LOAD_SECTOR_PAGE:
                return {...state, loadingItem: action.payload};
            case types.LOAD_SECTOR_PAGE_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_SECTOR_PAGE_FAILED:
                return {...state, loadingItem: false};


            /**
             * LOAD LOAD_NETWORKS_PAGE
             * */
            case types.LOAD_NETWORKS_PAGE:
                return {...state, network: {loadingItems: action.payload}};
            case types.LOAD_PAGES_NETWORK_PAGE_SUCCEEDED:
                return {...state, network: {item: action.payload, loadingItem: false}};
            case types.LOAD_NETWORKS_PAGE_FAILED:
                return {...state, network: {loadingItems: false}};


            /**
             * LOAD LOAD_NETWORK_PAGE
             * */
            case types.LOAD_NETWORK_PAGE:
                return {...state, network: {loadingItem: action.payload}};
            case types.LOAD_NETWORK_PAGE_SUCCEEDED:
                return {...state, network: {item: action.payload, loadingItem: false}};
            case types.LOAD_NETWORK_PAGE_FAILED:
                return {...state, network: {loadingItem: false}};

            /**
             * SAVE_CONTACT
             * */
            case types.SAVE_MESSAGE:
                return {...state, contact: {loadingItem: action.payload}};
            case types.SAVE_MESSAGES_SUCCEEDED:
                return {...state, contact: {item: action.payload, loadingItem: false, message: 'success'}};
            case types.SAVE_MESSAGE_FAILED:
                return {...state, contact: {loadingItem: false}};

            case types.RESET_MESSAGE:
                return {...state, contact: {message: null}};
            /**
             * LOAD_BLOG_PAGE
             * */

            case types.LOAD_BLOG_PAGE:
                return {...state, blog: {loadingItem: action.payload}};
            case types.LOAD_NEWS_PAGE_SUCCEEDED:
                return {...state, blog: {item: action.payload, loadingItem: false}};
            case types.LOAD_BLOG_PAGE_FAILED:
                return {...state, blog: {loadingItem: false}};
            case types.LOAD_COMPANY_PAGE_SUCCEEDED:
                return {...state, setting: {settings: action.payload}};
            default:
                return state;
        }
    }
;

export default sectorReducer;
