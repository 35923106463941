import * as React from "react";
import {injectIntl} from "react-intl";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg, intlConverter} from "../../../../constants/utils";
import StairHeader from "../../../layout/common/SatairHeader";
import {useParams} from "react-router-dom";
import {SectionOrigin} from '../../home/sections';
import {homeOrigin, homeSectors} from "../../../../constants/options/data";
import {useContext} from "react";
import {GlobalContext} from "../../../index";


const OriginPage = injectIntl(({intl}) => {
    const {page} = useParams();

    const {settings, language} = useContext(GlobalContext)
    console.log("================++++++++++", settings)
    return (
        <div className="single-departments-box-layout1 pt-0">
            <div className="item-content pt-3">
                <div className="item-content-wrap">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-departments-box-layout1 pt-5">

                                <div className="item-content pt-3">
                                    <div className="item-content-wrap">
                                        <h3 className="item-title title-bar-primary5">
                                            Origine
                                        </h3>
                                        <span className="sub-title">
                                            {/*{value.description}*/}
                                </span>
                                        <div class="row pt-4 bg-white">


                                            <div class="about-box-layout6 col-lg-12">
                                                <div class="sub-title">L’histoire de ZL</div>

                                                <p dangerouslySetInnerHTML={{
                                                    __html: intlConverter(settings?.origin, language)
                                                }} className="justify-content-center m-4"
                                                   style={{textAlign: 'justify'}}></p>

                                            </div>

                                        </div>

                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default React.memo(OriginPage)

