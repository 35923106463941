import React from "react";
import {intlConverter} from "../../../../constants/utils";
import {FormattedMessage} from "react-intl";


const Item = ({item, language, page, index}) => (

    <div role="tabpanel" className={`tab-pane bg-white p-4 ${item.id === page ? 'active show' : 'active show'}`}>
        <div className="single-departments-box-layout1">
            <div className="item-content" id={`department${item.id}`}>
                <div className="item-content-wrap m-4">
                    <h3 className="item-title title-bar-primary5 pb-2">
                        {intlConverter(item.name, language)}
                    </h3>

                    {item?.about ? <>
                        <h6 className="item-title title-bar-primary5 pb-2"
                            style={{fontSize: '14px'}}>
                            A propos
                        </h6>
                        <p dangerouslySetInnerHTML={{
                            __html: intlConverter(item.about, language)
                        }} className="justify-content-center m-4" style={{textAlign: 'justify'}}></p>
                    </> : null}

                    {item?.description ? <>
                        <h6 className="item-title title-bar-primary5 pb-2"
                            style={{fontSize: '14px'}}>
                            Description
                        </h6>
                        <p dangerouslySetInnerHTML={{
                            __html: intlConverter(item.description, language)
                        }} className="justify-content-center m-4" style={{textAlign: 'justify'}}></p>
                    </> : null}

                    {item.services?.length > 0 ? <>
                        <h6 className="item-title title-bar-primary5 pb-1" style={{fontSize: '14px'}}>
                            <FormattedMessage id={`app.label.services`}/>
                        </h6>
                        <ul className="department-info deps pt-0">
                            {item.services.map(v => <li className={''}>{intlConverter(v.name, language)}</li>)}
                        </ul>
                    </> : null}

                </div>
            </div>
        </div>
    </div>
)

export default Item