import * as React from "react";
import {useParams, withRouter} from "react-router";
import pages from "../../constants/options/_pages";
import GlobalHomePage from "./home";
import AboutPageManager from "./about";
import NetworkPage from "./network";
import SectorList from "./sector";
import PostListPage from "./blog";
import HelpPageManager from "./help";
import Covid19PageManager from "./covid19";
import GlobalDonatePage from "./donate";
import RecrutementPage from "./recrutement";

const PageManager = () => {
    const {module} = useParams();
    return _onLoadPage(module)
}


const  _onLoadPage=(module)=> {
    switch (module) {
        case pages.ABOUT_MODULE:
            return <AboutPageManager/>
        case pages.NETWORK_MODULE:
            return <NetworkPage/>
        case pages.SECTOR_MODULE:
            return <SectorList/>
        case pages.BLOG_MODULE:
            return <PostListPage/>
        case pages.HELP_MODULE:
            return <HelpPageManager/>
        case pages.COVID19_MODULE:
            return <Covid19PageManager/>
        case pages.DONATE_MODULE:
            return <GlobalDonatePage/>
        case pages.RECRUTEMENT_MODULE:
            return <RecrutementPage/>
        default: {
            return <GlobalHomePage/>
        }
    }
}


export default React.memo(withRouter(PageManager));
