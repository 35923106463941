/**
 * Created by louidorpc on 11/5/20.
 */
import React from 'react'
import  DonateForm from './donateForm'

const DonateDetail = () => (

    <section class="blog-wrap-layout2 bg-light-primary100 pt-3">
        <div class="container">
            <div class="row">
                {/*<div class="single-item col-md-4">*/}
                    {/*<div class="blog-box-layout4">*/}
                        {/*<div class="item-img">*/}
                            {/*<a>*/}
                                {/*<img src="assets/img/department/department01.jpg" class="img-fluid" alt="blog"/>*/}
                            {/*</a>*/}

                        {/*</div>*/}
                        {/*<div class="item-content">*/}
                            {/*<h3 class="item-title">*/}
                                {/*<a href="single-news.html">Votre don sauvera des vies</a>*/}
                            {/*</h3>*/}
                            {/*<p>*/}
                                {/*«Chaque soir, quand je vais me coucher, je m'inquiète de la façon dont je vais tenir*/}
                                {/*toutes les promesses que j'ai faites. Et chaque matin, à mon réveil, je pense que je*/}
                                {/*n'ai pas fait assez de promesses.*/}

                            {/*</p>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}


                <div class="col-md-12">
                    <div class="blog-box-layout4" style={{backgroundColor: '#f5f5f7'}}>
                        <p className="text-center"><img src="/assets/img/secure-png-.png" width="120px"/></p>
                        <h4 className="text-center">Don sécurisé</h4>
                        <DonateForm/>

                    </div>
                </div>
            </div>
        </div>


    </section>
)
export default  DonateDetail;
