/**
 * Created by louidorpc on 11/5/20.
 */
import * as React from "react";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {injectIntl} from "react-intl";
import DonateDetail from './sections/details'
import {useParams} from "react-router-dom";
import StairHeader from "../../layout/common/SatairHeader";


const GlobalDonatePage = injectIntl(({intl}) => {
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.donate`));

    return (
        <>
        <StairHeader title={`app.label.donate`} subtitle={`zl_origin`} mt="50px"/>
        <DonateDetail/>
        </>
    );
});

export default React.memo(GlobalDonatePage)

