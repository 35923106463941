import * as React from "react";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router-dom";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";
import StairHeader from "../../../layout/common/SatairHeader";
import {News} from '../../../../constants/options/data'
import SideBar from "./SideBar";
import PageContent from "./PageContent";

const SectorDetailPage = injectIntl(({intl}) => {
    const {module, page, id} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const dispatch = useDispatch();

    const criteria = {options: ['news']}


    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {loading, items} = useSelector(state => ({
        loading: state.pagesReducer.blog.loadingItems,
        items: state.pagesReducer.blog.item,
    }));


    return (<>
            <StairHeader title={`blog`} subtitle={`detail`} mt={`20px`}/>
            <section class="single-department-wrap-layout1 bg-light-primary100 pt-4">
                <div class="container">

                    {items && items.length > 0 ?
                        <SideBar items={items}/>
                        : null}
                </div>
            </section>
        </>
    );
});


export default React.memo(withRouter(SectorDetailPage))

