import * as React from "react";
import {menu, socialItems} from '../../../constants/options/footer'
import MenuBlock from "./MenuBlock";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {getIntlMsg} from "../../../constants/utils";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {Button} from 'react-bootstrap';
import {DonateModal} from '../../../components/pages/donate/sections/donateModal';
import {useContext} from "react";
import {GlobalContext} from "../../index";

const Footer = injectIntl(({intl, handleChangeLanguage, language, history}) => {

    const {settings} = useContext(GlobalContext);
    const [state, setState] = React.useState({
        lang: language,
        label: 'Francais', show: false
    });
    const langs = [
        {value: 'fr', label: 'Francais', logo: "/assets/img/fr.png"},
        {value: 'en', label: 'Anglais', logo: "/assets/img/en.png"}
    ]

    function setShowM() {
        setState({
            show: true, lang: language,
            label: 'Francais'
        })
    }

    function hide() {
        setState({
            show: false, lang: language,
            label: 'Francais'
        })
    }

    const {lang, label, show} = state;


    const handleChangeLang = e => {

        const val = e.target.value;
        let item = langs.find(v => v.value === val);
        setState({lang: item.value, label: item.label})
        handleChangeLanguage(val)
        // alert(val)
    }

    return (
        <>
            <footer>
                <section className="footer-top-wrap" style={{zIndex: '999'}}>
                    <div className="container">
                        <div className="row">
                            <div className="single-item col-lg-3 col-md-6 col-12">
                                <div className="footer-box">
                                    <div className="footer-logo">
                                        <Link to="/">
                                            {settings?.logos?.map(v => {
                                                if (v.type?.name?.frContent === "Footer") {
                                                    return (
                                                        <img src={v.fileName}
                                                             className="img-fluid"
                                                             alt="footer-logo" width="100px"/>
                                                    )
                                                }
                                            })}
                                        </Link>
                                    </div>
                                    <div className="footer-about">
                                        {/*<p> {settings.companyAbout}</p>*/}
                                    </div>
                                    <div className="footer-contact-info">
                                        <ul>
                                            <li>
                                                <i className="fas fa-map-marker-alt"/>
                                                {settings?.addresses?.join(' / ')}
                                            </li>
                                            <li>
                                                <i className="fas fa-phone"/>
                                                {settings?.phones?.join(' / ')}
                                            </li>
                                            <li>
                                                <i className="far fa-envelope"/>
                                                {settings?.emails?.join(' / ')}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {menu.map((item, i) =>
                                <MenuBlock {...item} key={i}/>
                            )}
                        </div>
                    </div>
                </section>
                <section className="footer-center-wrap">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-lg-4 col-12">
                                <div className="footer-social">
                                    <ul>
                                        <li>
                                            <FormattedMessage id={`app.label.follow_us`}/> :
                                        </li>
                                        {settings?.socialLinks?.map((item, i) =>
                                            <li key={i}>
                                                <a href={item.link} target="_blank">
                                                    <i className={item.type === 'FACEBOOK' ? `fab fa-facebook-f` : item.type === 'TWITTER' ? 'fab fa-twitter' : item.type === 'INSTAGRAM' ? 'fab fa-instagram' : ''}/>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12">
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className="newsletter-title">
                                            <h4 className="item-title">
                                                <FormattedMessage id={`app.label.stay_informed`}/> :
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="newsletter-form">
                                            <div className="input-group stylish-input-group">
                                                <input type="text" className="form-control"
                                                       placeholder={getIntlMsg(intl, 'app.label.insert_email')}/>
                                                <span className="input-group-addon">
                                                <button type="submit">
                                                    <span aria-hidden="true">
                                                        <FormattedMessage id={`app.label.sign_up`}/> !
                                                    </span>
                                                </button>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer-bottom-wrap">
                    <div className="copyright">
                        <FormattedMessage id={`app.label.copyright`}/>
                    </div>
                </section>

            </footer>
            <div className="row donate">

                <div className="col-md-12 col-xs-12 pb-0 m-0 pt-2">

                    <Button className="btn-primary float-left"
                            onClick={setShowM} style={{
                        zIndex: 9,
                        fontSize: '18px',
                        background: "#ff6900",
                        border: 'none',
                        padding: "5px 5px"
                    }}>
                        <FormattedMessage id={`app.label.donate`}/>
                    </Button>

                    <TextField
                        id="outlined-select-currency"
                        className="float-right"
                        select
                        value={lang}
                        style={{
                            fontSize: '16px',
                            color: '#fff',
                            background: "fff",
                            position: 'relative',
                        }}
                        onChange={(e) => handleChangeLang(e)}
                    >
                        {langs.map((option) => (
                            <MenuItem key={option.value} value={option.value}
                                      style={{
                                          fontSize: '16px',
                                          color: '#fff',
                                          background: "fff",
                                          position: 'relative',
                                      }}>
                                <img src={option.logo} width="25"></img>
                            </MenuItem>
                        ))}
                    </TextField>

                </div>


            </div>
            {show ? <DonateModal sh={show} hide={hide}/> : null}
        </>
    )
})

export default React.memo(Footer)

